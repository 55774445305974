// extracted by mini-css-extract-plugin
export var lbContainerOuter = "r_r6";
export var lbContainerInner = "r_r7";
export var movingForwards = "r_r8";
export var movingForwardsOther = "r_r9";
export var movingBackwards = "r_sb";
export var movingBackwardsOther = "r_sc";
export var lbImage = "r_sd";
export var lbOtherImage = "r_sf";
export var prevButton = "r_sg";
export var nextButton = "r_sh";
export var closing = "r_sj";
export var appear = "r_sk";
// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "p_qG d_gs d_cr";
export var heroHeaderCenter = "p_gt d_gt d_cr d_dv";
export var heroHeaderRight = "p_gv d_gv d_cr d_dw";
export var heroParagraphLeft = "p_qH d_gp d_cv";
export var heroParagraphCenter = "p_gq d_gq d_cv d_dv";
export var heroParagraphRight = "p_gr d_gr d_cv d_dw";
export var heroBtnWrapperLeft = "p_qJ d_d1 d_d0 d_v d_by d_bF";
export var heroBtnWrapperCenter = "p_qK d_d2 d_d0 d_v d_by d_bC";
export var heroBtnWrapperRight = "p_qL d_d3 d_d0 d_v d_by d_bG";
export var overlayBtnWrapper = "p_qM d_gn d_Z d_6 d_7 d_8 d_bm d_cb";
export var design4 = "p_qN d_gm d_Z d_6 d_7 d_bm";
export var heroExceptionSmall = "p_qP q_qP";
export var heroExceptionNormal = "p_qQ q_qQ";
export var heroExceptionLarge = "p_qR q_qR";
export var Title1Small = "p_qS q_qS q_qZ q_q0";
export var Title1Normal = "p_qT q_qT q_qZ q_q1";
export var Title1Large = "p_qV q_qV q_qZ q_q2";
export var BodySmall = "p_qW q_qW q_qZ q_rh";
export var BodyNormal = "p_qX q_qX q_qZ q_rj";
export var BodyLarge = "p_qY q_qY q_qZ q_rk";
// extracted by mini-css-extract-plugin
export var alignDiscLeft = "n_p5 d_fn d_bF d_dt";
export var alignLeft = "n_p6 d_fn d_bF d_dt";
export var alignDiscCenter = "n_p7 d_fp d_bC d_dv";
export var alignCenter = "n_bN d_fp d_bC d_dv";
export var alignDiscRight = "n_p8 d_fq d_bG d_dw";
export var alignRight = "n_p9 d_fq d_bG d_dw";
export var footerContainer = "n_qb d_dV d_bV";
export var footerContainerFull = "n_qc d_dS d_bV";
export var footerHeader = "n_kc d_kc";
export var footerTextWrapper = "n_qd d_v";
export var footerDisclaimerWrapper = "n_kk d_kk d_cj";
export var badgeWrapper = "n_qf d_v d_by d_bN d_bD d_bH";
export var footerDisclaimerRight = "n_qg undefined";
export var footerDisclaimerLeft = "n_qh undefined";
export var verticalTop = "n_qj d_by d_bH d_bM d_bK";
export var firstWide = "n_qk";
export var disclaimer = "n_ql d_by d_bH";
export var socialDisclaimer = "n_qm";
export var left = "n_qn";
export var wide = "n_qp d_cx";
export var right = "n_qq d_bJ";
export var line = "n_fj d_fk d_ct";
export var badgeDisclaimer = "n_qr d_bB d_bN d_bH";
export var badgeContainer = "n_qs d_by d_bG d_bN";
export var badge = "n_qt";
export var padding = "n_qv d_c6";
export var end = "n_qw d_bG";
export var linkWrapper = "n_qx d_dz";
export var link = "n_mz d_dz";
export var colWrapper = "n_qy d_cw";
export var consent = "n_f d_f d_bB d_bN";
export var media = "n_qz d_bw d_dx";
export var itemRight = "n_qB";
export var itemLeft = "n_qC";
export var itemCenter = "n_qD";
export var exceptionWeight = "n_qF q_rq";